
*{
  letter-spacing: 1px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

blockquote {
  background: #f9f9f9;
  border-left: 5px solid #ccc;
  padding-left: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.domainmail-continer{
  width: 60%;
}
.requestform{
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
}
.formheader{
  font-size: 18px;
  padding: 5px;
  color: rgb(20, 6, 143);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginform{
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction:column;
  padding: 30px;
  margin-top: 30%;
}
.login-form{
 display: flex;
 flex-direction: column;
 width: 100%;
 margin-top: 20px;
}




.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 50%;
 float: right;
}
.compseinput{
 padding: 5px;
 border: 1px solid #b0afae;
}
.compseinput:focus{
  padding: 5px;
  border: 1px solid #b0afae;
 }
.logo{
width: 200px;
height: 100px;
margin-bottom: 50px;
}

.home{
  background-color: #fff;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  overflow-x: scroll;
  padding: 5px 5px 5px 5px;

}
.home_header{
  display: flex; 
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: #fff;
  padding: 5px 10px 5px 10px;

  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
}
.name_head{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight:500;
}
  .name{
    font-size: 14px;
    font-weight:500;
    margin-left: 5px;
    white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;

  }
.user_logo{
  width: 40px;
  height: 40px;
  background-color: #ff9900;
  border-radius: 50%;
  /* margin-right: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.home_subheader{
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom-color: #a4a4a4;
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  font-size: 16px;
  letter-spacing: 1px;
  color: #080808;
}
.Compose{
  color: rgba(232, 127, 7, 0.79);
  height: 30px;
  background-color: #fff;
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
  align-items: center;
  display: flex;
  letter-spacing: 1px;

}
.mail_body_filed:focus{
  outline: none;

}
.mail_body_filed{
  margin-top: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #97989b;
}
.inp {
  border:none;
  border-bottom: 1px solid #97989b;
  padding: 5px 10px;
  outline: none;
}
[contenteditable] {
  height: 15rem;
  border: 1px solid;
  resize: both;
}

.mail_list{
  background-color: #fff;
  padding: 5px;
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.list_sub{
  font-size: 12px;
}
.list_sub_item{
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mailbodyheader_sub{
  color: #686767;
  word-break:break-all;
  font-size: 14px;
  margin-top: 10px;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  padding-bottom: 10px;
  border-bottom-color: #b0afae;

}
.mailbodyheader_sub span{
  font-size: 14px;
}
span.cc{
  font-weight: normal;
}
.mailcontiner{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow:scroll;
}
.mailbodyheader{
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 18px;
  flex-direction: column;
}
.mailbodyheader_main{
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  padding-bottom: 10px;
  border-bottom-color: #ff9900;
}
.mailbody{
   display: flex;
   width: 100%;
   flex-direction: column;
   padding-bottom: 70px;
}
.mailbodystyle{
  font-size: 15px;
}
.bodyloading{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.mailfooder{
  display: flex;
  justify-content:space-around;
  background-color: #fff;
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  padding-right: 5px;
  margin-right: 5px;
  align-items: center;
}
.mailfoodericon{
  color: #61dafb;
}
.attachments{
  display: flex;
  width: 100%;
  flex-direction:column;
  border-top-style: solid;

  border-top-width: 1px;
  border-top-color: #000;
}
.att_name{
  font-weight: 700;
  color: #000;
}
.att_link{
  color: #4e0ce8;
}

.status_btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  border-radius: 5px;
  padding: 10px;
  background-color: #ff9900;
  margin-left: 5px;
  color: #fff;
}
.compose{
  display: flex;
  position:absolute;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 153, 0, 0.4);
  border-radius: 50%;
  box-shadow: 0px 0px 73px 12px rgba(0,0,0,0.1);

}
.listtitle{
  font-size: 15px;
  color: #282A3A;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-tabs-tab.ant-tabs-tab-active {
  border-top: 2px solid #ff9900 !important;
  z-index: 2;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff9900 !important; 
  font-weight: 500;
}
.mail_box{
  display: flex;
  flex-direction: column;
  align-items: center;

}
 


.menu_body{
  display: flex;
  background-color: #fff;

  width: 100%;
  height: 100%;
  align-items: center;
  justify-content:space-around;
  flex-direction: column;
}
.menucard{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  height: 200px;
  border-radius: 5px;
  background-color: #eeeeee;
  color: #000;
  font-weight: bold;
  letter-spacing: 1px;
}
.sentmail{
  width: 50px;
}

